<template>
  <div class="page">
    <!-- <Head :headTitle="headTitle" /> -->
    <div class="welWord">{{ welMsg }}</div>
  </div>
</template>
    
    <script>
// import 'assets/css/style.css'
// import Head from "@/components/head_wm.vue";
// import bgAnimateVue from "@/components/bg_animate.vue";
import axios from "axios";
export default {
  data() {
    return {
      welMsg: "欢迎大家欢迎大家欢迎大家欢家欢迎大家欢迎大家",
    };
  },
  components: {
    // Head,
    // bgAnimateVue,
  },
  computed: {},
  created() {
    this.getWelcome();
  },
  mounted() {},
  methods: {
    getWelcome() {
      axios
        .get(
          `https://zkscr.shouxinfund.com/dev-api/system/item/get/greet/XM05072818`
        )
        .then((res) => {
          console.log(res.data);
          this.welMsg = res.data.msg;
        });
      // axios({
      //     url:`http://zkscr.shouxinfund.com/dev-api/system/item/getGreet?itemId = XM03276791`,
      //     method:"GET",
      //     timeout:5000,
      // })
    },
  },
};
</script>
    <style scoped>
.page {
  /* position: relative; */
  background: url(../assets/images/welcome.png) no-repeat center;
  background-size: 100% 100%;
}
.page .welWord {
  font-family: "YouSheBiaoTiHei";
  width: 100%;
  text-align: center;
  font-size: 240px;
  /* font-weight: bold; */
  letter-spacing: 10px;
  background: linear-gradient(#fff 50%, rgba(0, 0, 0, 0.15) 100%);
  -webkit-background-clip: text;
  color: transparent;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>